<template >
  <b-container fluid>
    <loading :active.sync="isWorkingClient" :is-full-page="true"></loading>
    <b-card  class="c-shadow" style="border: 1px solid #858585">
    <h4>Categorias del catálago de trámites</h4><br>
    <b-row align-v="start"   > <!---  class="d-none d-md-block" ocultar en resoluciones menores a md---->
      <b-col cols="12" md="6" class="d-none d-md-block">
        <h6>Nombre</h6>
      </b-col>
      <b-col cols="12" md="2" class="d-none d-md-block">
        <h6>Estado</h6>
      </b-col>
      <b-col cols="12" md="2" class="d-none d-md-block">
        <h6>Icono</h6>
      </b-col>
    </b-row>  
    <b-row  align-v="start" v-for="(categoria) in $v.categoriasList.$each.$iter" :key="categoria.idCategoria.$model">
      <b-col style="border-top: 1px solid" cols="12" class="d-block d-md-none">
        <br>
        <h6>Nombre</h6>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-input :disabled="!categoria.activa.$model" style="text-transform: uppercase" :state="!categoria.$invalid" v-model.trim="categoria.nombreCategoria.$model" ></b-form-input>
        <div class="error" v-if="!categoria.nombreCategoria.minLength">El nombre es demasiado corto</div>
        <div class="error" v-if="!categoria.nombreCategoria.maxLength">El nombre es demasiado largo</div>
        <div class="error" v-if="!categoria.nombreCategoria.required">Debe ingresar el nombre de la categoria</div>
      </b-col>
      <b-col cols="6" class="d-block d-md-none">
        <br>
        <h6>Estado</h6>
      </b-col>
      <b-col cols="6" class="d-block d-md-none">
        <br>
        <h6>Icono</h6>
      </b-col>
      <b-col cols="6" md="2">
        <b-form-select  v-model="categoria.activa.$model" :options="optionsEstado" ></b-form-select>
      </b-col>
      <b-col cols="6" md="2">
        <b-dropdown block size="sm" :disabled="!categoria.activa.$model" :text="categoria.icon.$model" >
          <b-dropdown-item @click="changeModelIcon('agua.png', categoria)" > <img src="/imgs/agua.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> agua.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIcon('arbol.png', categoria)" > <img src="/imgs/arbol.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> arbol.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIcon('autobus.png', categoria)" > <img src="/imgs/autobus.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> autobus.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIcon('casa.png', categoria)" > <img src="/imgs/casa.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> casa.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIcon('certificado.png', categoria)" > <img src="/imgs/certificado.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> certificado.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIcon('dinero.png', categoria)" > <img src="/imgs/dinero.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> dinero.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIcon('documento.png', categoria)" > <img src="/imgs/documento.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> documento.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIcon('documento_firma.png', categoria)" > <img src="/imgs/documento_firma.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> documento_firma.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIcon('documento_personal.png', categoria)" > <img src="/imgs/documento_personal.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> documento_personal.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIcon('documentos.png', categoria)" > <img src="/imgs/documentos.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> documentos.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIcon('family-room.png', categoria)" > <img src="/imgs/family-room.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> family-room.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIcon('libro.png', categoria)" > <img src="/imgs/libro.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> libro.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIcon('mapa.png', categoria)" > <img src="/imgs/mapa.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> mapa.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIcon('negocio.png', categoria)" > <img src="/imgs/negocio.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> negocio.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIcon('online-pay.png', categoria)" > <img src="/imgs/online-pay.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> online-pay.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIcon('palacio.png', categoria)" > <img src="/imgs/palacio.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> palacio.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIcon('stats.png', categoria)" > <img src="/imgs/stats.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> stats.png</b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col cols="12" class="d-block d-md-none">
        <br>
      </b-col>
      <b-col cols="12" md="2">
        <b-button  :disabled="categoria.$invalid" block size="sm" variant="success" @click="saveCategoria(categoria.idCategoria.$model)">Guardar</b-button>
      </b-col>
      <b-col cols="12" class="d-block d-md-none"> <!----- class="d-block d-md-none" ver solo en resoluciones menores a md---->
        <br><br>
      </b-col>
    </b-row>  
    </b-card><br>
    <!------------------------- NUEVA CATEGORIA --------------------------->
    <b-card  class="c-shadow" style="border: 1px solid #858585">
    <b-form-checkbox size="lg" v-model="isVerNuevaCat"><h4>Agregar nueva categoria</h4></b-form-checkbox>
    
    <b-row v-if="isVerNuevaCat" align-v="start" >
      <br>
      <b-col cols="12" md="6">
        <h6>Nombre</h6>
        <b-form-input  :state="!$v.categoriaNueva.$invalid" style="text-transform: uppercase" v-model.trim="$v.categoriaNueva.nombreCategoria.$model" ></b-form-input>
        <div class="error" v-if="!$v.categoriaNueva.nombreCategoria.minLength">El nombre ed demasiado corto</div>
        <div class="error" v-if="!$v.categoriaNueva.nombreCategoria.maxLength">El nombre es demasiado largo</div>
        <div class="error" v-if="!$v.categoriaNueva.nombreCategoria.required">Debe ingresar el nombre de la nueva categoria</div>
      </b-col>
      <b-col cols="12" md="2">
        <br>
        <h6>Icono</h6>
        <b-dropdown block size="sm"  :text="categoriaNueva.icon" > <br>
          <b-dropdown-item @click="changeModelIconNvaCat('agua.png')" > <img src="/imgs/agua.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> agua.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIconNvaCat('arbol.png')" > <img src="/imgs/arbol.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> arbol.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIconNvaCat('autobus.png')" > <img src="/imgs/autobus.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> autobus.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIconNvaCat('casa.png')" > <img src="/imgs/casa.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> casa.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIconNvaCat('certificado.png')" > <img src="/imgs/certificado.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> certificado.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIconNvaCat('dinero.png')" > <img src="/imgs/dinero.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> dinero.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIconNvaCat('documento.png')" > <img src="/imgs/documento.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> documento.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIconNvaCat('documento_firma.png')" > <img src="/imgs/documento_firma.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> documento_firma.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIconNvaCat('documento_personal.png')" > <img src="/imgs/documento_personal.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> documento_personal.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIconNvaCat('documentos.png')" > <img src="/imgs/documentos.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> documentos.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIconNvaCat('family-room.png')" > <img src="/imgs/family-room.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> family-room.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIconNvaCat('libro.png')" > <img src="/imgs/libro.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> libro.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIconNvaCat('mapa.png')" > <img src="/imgs/mapa.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> mapa.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIconNvaCat('negocio.png')" > <img src="/imgs/negocio.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> negocio.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIconNvaCat('online-pay.png')" > <img src="/imgs/online-pay.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> online-pay.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIconNvaCat('palacio.png')" > <img src="/imgs/palacio.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> palacio.png</b-dropdown-item>
          <b-dropdown-item @click="changeModelIconNvaCat('stats.png')" > <img src="/imgs/stats.png" alt="" border="0" height="30" width="30" style="max-width: 130px; background-color:#636363;"> stats.png</b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>  
    <br>
    <b-button  v-if="isVerNuevaCat" size="lg" :disabled="$v.categoriaNueva.$invalid" variant="success" @click="saveNewCategoria(categoriaNueva)">Agregar</b-button>
    </b-card><br><br>
  </b-container>
</template>

<script>
  import { minLength, maxLength, required} from 'vuelidate/lib/validators';
  import { mapState} from 'vuex';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import {  updateSaveCategoriaClient } from '@/axios-tools';

  export default {
    computed: mapState({
      token: state => state.client.token,
      isWorkingClient: state => state.client.isWorkingClient,
      categoriasList: state => state.client.categoriasList,
      categoriaNueva: state => state.client.categoriaNueva
    }),
    components: {
      Loading
    },
    data() {
      return {
        isVerNuevaCat:false,
        optionsEstado:[
          {text:"ACTIVA", value:true},
          {text:"INACTIVA", value:false}
        ]
      }
    },
    methods: {
      saveCategoria(idCategoria){
        for (let index = 0; index < this.categoriasList.length; index++) {
          if(this.categoriasList[index].idCategoria == idCategoria){
            updateSaveCategoriaClient(this.token, this.categoriasList[index], this.$store, this.$router);
            break;
          }          
        }
      },
      saveNewCategoria(categoria){
         updateSaveCategoriaClient(this.token, categoria, this.$store, this.$router);
      },
      changeModelIcon(icono, categoria){
        for (let index = 0; index < this.categoriasList.length; index++) {
            if(categoria.idCategoria == this.categoriasList[index].idCategoria){
                this.categoriasList[index].icon = icono;
            }
        }
        this.$store.commit('updateCategoriasList', JSON.parse(JSON.stringify(this.categoriasList)));
      },
      changeModelIconNvaCat(icono){
        this.categoriaNueva.icon = icono;
        this.$store.commit('updateCategoriaNueva', JSON.parse(JSON.stringify(this.categoriaNueva)));
      }
    },
    validations: {
      categoriasList: {
        $each: {
          nombreCategoria: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(100)
          },
          activa:{},
          icon:{},
          idCategoria:{}
        }
      },
      categoriaNueva:{
        nombreCategoria: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(100)
        }
      }  
        
    }
  }
</script>
<style>
.input-pass-admin{
  margin-bottom:5px;
}
div.error{
  color: red;
}
</style>